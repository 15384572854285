





































import { Component } from 'vue-property-decorator';
import PermissionService from '@common-src/service/permission';
import { BaseComponent } from '@common-src/mixins/base-component';
import { PermissionEntityModel } from '@common-src/entity-model/permission-entity';

@Component
export default class PermissionServiceDialog extends BaseComponent {
    modelId: string;
    resourceTree: Array<any> = null;
    dialogVisible: boolean = false;
    dialogTitle: string = '编辑权限服务';

    dialogOpen(modelId: string, modelName: string): void {
        this.modelId = modelId;
        this.dialogTitle = `服务编辑-${modelName}`;
        this.dialogVisible = true;
        this.startFullScreenLoading('正在加载服务资源');
        PermissionService.getResourceTree(this.modelId).then(res => {
            this.resourceTree = res;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    dialogOK(): any {
        const selectPermissionTree = _.map(_.filter(this.resourceTree, item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService());
        const resources = this.getSelectPermissions(selectPermissionTree);
        return PermissionService.resourceBind(this.modelId, resources).then(res => {
            this.showMessageSuccess('保存服务成功');
        });
    }

    getSelectPermissions(treeData: Array<any>) {
        const permissions = [];
        _.forEach(treeData, item => {
            permissions.push({ resourceCode: item.resourceCode, resourceName: item.resourceName });
            if (item.children && item.children.length > 0) {
                permissions.push(...this.getSelectPermissions(item.children));
            }
        });
        return permissions;
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
