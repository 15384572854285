var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-large",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "permission-service-dialog-component" },
        _vm._l(_vm.resourceTree, function (menu) {
          return _c(
            "div",
            { key: menu.id, staticClass: "menu-item-wrapper" },
            [
              _c(
                "div",
                { staticClass: "menu-item-header" },
                [
                  _c(
                    "a-checkbox",
                    {
                      attrs: { indeterminate: menu.Indeterminate },
                      model: {
                        value: menu.AllSelect,
                        callback: function ($$v) {
                          _vm.$set(menu, "AllSelect", $$v)
                        },
                        expression: "menu.AllSelect",
                      },
                    },
                    [_vm._v(_vm._s(menu.name))]
                  ),
                ],
                1
              ),
              menu.children && menu.children.length > 0
                ? _c(
                    "a-descriptions",
                    { attrs: { bordered: "", column: 1 } },
                    [
                      _vm._l(menu.children, function (page) {
                        return [
                          _c(
                            "a-descriptions-item",
                            { key: page.id },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "label" }, slot: "label" },
                                [
                                  _c(
                                    "a-checkbox",
                                    {
                                      attrs: {
                                        indeterminate: page.Indeterminate,
                                      },
                                      model: {
                                        value: page.AllSelect,
                                        callback: function ($$v) {
                                          _vm.$set(page, "AllSelect", $$v)
                                        },
                                        expression: "page.AllSelect",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(page.name) + " ")]
                                  ),
                                ],
                                1
                              ),
                              _vm._l(page.children, function (func) {
                                return [
                                  _c(
                                    "a-checkbox",
                                    {
                                      key: func.id,
                                      model: {
                                        value: func.select,
                                        callback: function ($$v) {
                                          _vm.$set(func, "select", $$v)
                                        },
                                        expression: "func.select",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(func.name) + " ")]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("jtl-button", { on: { click: _vm.dialogClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "jtl-button",
            {
              attrs: { type: "primary", "click-prop": _vm.dialogOK.bind(this) },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }