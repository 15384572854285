var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permission-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("权限管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "权限分组", bordered: false } },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            "selected-keys": _vm.selectedPermissionGroupId,
                          },
                        },
                        [
                          _vm._l(_vm.permissionGroupList, function (item) {
                            return [
                              _c(
                                "a-menu-item",
                                {
                                  key: item.id,
                                  on: {
                                    click: function ($event) {
                                      return _vm.menuClick(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      [
                        _c(
                          "div",
                          { attrs: { slot: "title" }, slot: "title" },
                          [
                            _c("span", [_vm._v("权限列表")]),
                            _c(
                              "a-radio-group",
                              {
                                staticClass: "inline-block",
                                staticStyle: { "margin-left": "16px" },
                                attrs: {
                                  "table-header-child": "",
                                  "button-style": "solid",
                                },
                                model: {
                                  value: _vm.resourceType,
                                  callback: function ($$v) {
                                    _vm.resourceType = $$v
                                  },
                                  expression: "resourceType",
                                },
                              },
                              [
                                _c(
                                  "a-radio-button",
                                  { attrs: { value: _vm.ResourceType.PAGE } },
                                  [_vm._v("页面功能资源")]
                                ),
                                _c(
                                  "a-radio-button",
                                  {
                                    attrs: { value: _vm.ResourceType.SERVICE },
                                  },
                                  [_vm._v("服务资源")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _vm.AppPermissionGroup
                              ? _c(
                                  "jtl-button",
                                  {
                                    attrs: {
                                      "table-header-child": "",
                                      type: "primary",
                                      "click-prop": _vm.asyncAppMenu,
                                    },
                                  },
                                  [_vm._v("同步应用管理")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": _vm.syncWebMenu,
                                },
                              },
                              [_vm._v("同步Web菜单")]
                            ),
                            _vm._v("  "),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": _vm.importPermissionClick,
                                },
                              },
                              [_vm._v("导入")]
                            ),
                            _vm._v("  "),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": _vm.exportPermissionClick,
                                },
                              },
                              [_vm._v("导出")]
                            ),
                            _vm._v("  "),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                },
                                on: { click: _vm.addPermissionClick },
                              },
                              [_vm._v("+ 添加")]
                            ),
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.TableColumns,
                            "data-source": _vm.listData,
                            pagination: false,
                            scroll: _vm.tableScroll,
                            "row-key": "id",
                            size: _vm.TableSize,
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "action",
                                fn: function (text, record) {
                                  return record.id
                                    ? [
                                        record.category ===
                                        _vm.ResourceType.PAGE
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "jtl-edit-link",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addChildClick(
                                                          record
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("新增资源")]
                                                ),
                                                _c("a-divider", {
                                                  attrs: { type: "vertical" },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("span", {
                                              staticStyle: {
                                                width: "73px",
                                                display: "inline-block",
                                              },
                                            }),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-edit-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPermissionClick(
                                                  record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认删除?",
                                              "ok-text": "确认",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.deleteClick(record)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "jtl-del-link" },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "serviceResources",
                                fn: function (text, record) {
                                  return record.id
                                    ? [
                                        _c(
                                          "a-row",
                                          {
                                            attrs: {
                                              type: "flex",
                                              gutter: [4, 4],
                                            },
                                          },
                                          _vm._l(
                                            record.resources,
                                            function (item, index) {
                                              return _c(
                                                "a-col",
                                                { key: index },
                                                [
                                                  _c(
                                                    "a-tag",
                                                    { attrs: { title: item } },
                                                    [
                                                      _vm._v(
                                                        " " + _vm._s(item) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "serviceAction",
                                fn: function (text, record) {
                                  return record.id &&
                                    record.category === _vm.ResourceType.PAGE
                                    ? [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-edit-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editPermissionServiceClick(
                                                  record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("修改")]
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("import-permission-dialog", {
        ref: "importPermissionDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("permission-service-dialog", {
        ref: "permissionServiceDialog",
        on: { dialogOK: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }